<template>
  <view>
    <!-- <van-loading type="spinner" /> -->
  </view>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      productLinkId: '',
    };
  },
  created() {
    Toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.productLinkId = this.$route.query.productLinkId;
    setTimeout(() => {
      Toast.clear();
      this.goUrl();
    }, 1500);
  },
  methods: {
    goUrl() {
      console.log(ap);
      const indexUrl = 'https://www.juxincha.com/query/#/';
      // const indexUrl = 'http://v2.xinyuyibai.com/query/#/'
      const url = `${indexUrl}?productLinkId=${this.productLinkId}`;
      const userAgent = navigator.userAgent.toLowerCase();
      // console.log(userAgent)
      if (userAgent.indexOf('alipay') !== -1) {
        //支付宝环境下
        ap.redirectTo({
          url,
          success: (res) => {
            console.log(res);
          },
          fail: (err) => {
            console.log(err);
          },
        });
      } else {
        //普通浏览器
        // window.location.href = url;
      }
    },
  },
};
</script>

<style scoped>
/* page {
		background-color: #fff;
	} */
</style>
